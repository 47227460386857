<template>
  <div>
    <div class="m-b-10">
      <RadioGroup v-model="query.type" size="small" type="button" @on-change="changeTaskType">
        <Radio v-for="item in taskTypeArray" :key="item.value" :label="item.value">{{ item.name }}</Radio>
      </RadioGroup>
    </div>
    <Row :gutter="8" class="m-b-10">
      <i-col span="3">
        <Select v-model="query.assetId" size="small" placeholder="资产" :clearable="true" @on-change="handleChangeAsset">
          <Option v-for="item in companyAssetArray" :key="'asset_' + item.value" :value="item.value">{{ item.name }}
          </Option>
        </Select>
      </i-col>
      <i-col span="3" v-if="query.type !== 10">
        <Select v-model="query.stationId" size="small" placeholder="站点" :clearable="true">
          <Option v-for="item in stationArray" :key="'station_' + item.id" :value="item.id">{{ item.name }}</Option>
        </Select>
      </i-col>
      <i-col span="3">
        <DatePicker type="daterange" v-model="actionDate" transfer size="small" style="width: 100%" placeholder="作业日期"
          @on-change="handelChangeActionDate"></DatePicker>
      </i-col>
      <i-col span="3">
        <Select v-model="query.status" size="small" placeholder="状态" :clearable="true">
          <Option v-for="item in taskStatusArray" :key="'s_' + item.value" :value="item.value">{{ item.name }}</Option>
        </Select>
      </i-col>
      <i-col span="3" v-if="[1, 7, 11, 12, 13].includes(query.type)">
        <Select v-model="query.pictureResource" size="small" placeholder="画面来源" :clearable="true">
          <Option v-for="item in pictureSourceArray" :key="'ps_' + item.value" :value="item.value">{{ item.name }}
          </Option>
        </Select>
      </i-col>
      <i-col span="3">
        <Select v-model="query.adType" size="small" placeholder="任务用途" :clearable="true">
          <Option v-for="item in adTypeArray" :key="'ot_' + item.value" :value="item.value">{{ item.name }}</Option>
        </Select>
      </i-col>
      <i-col span="4">
        <i-input size="small" placeholder="资源编号/所属站台/小组名称/客户品牌" v-model='query.keyword'></i-input>
      </i-col>
      <i-col span="2">
        <Button type="primary" class="m-r-5" size="small" icon="ios-search" @click="handleSearch">搜索</Button>
      </i-col>
    </Row>
    <Row :gutter="8" class="m-b-5">
      <i-col span="12">
        <Button type="success" size="small" class="m-r-5" :disabled="selectedTaskItemIds.length === 0"
          @click="handleSetWorkgroup">配置小组</Button>
        <Button type="error" size="small" class="m-r-5" :disabled="selectedTaskItemIds.length === 0"
          @click="handleCancelWorkgroup">取消分配</Button>
        <Button type="success" size="small" @click="handelDonwload">下载</Button>
      </i-col>
      <i-col span="12">
        <div class="paging_style">
          <Page size="small" :total="tableData.totalRow" :page-size="query.pageSize" show-total show-sizer
            :page-size-opts="pagesizeSetting" @on-page-size-change='changePageSize' :current="query.pageNumber"
            @on-change="changePage"></Page>
        </div>
      </i-col>
    </Row>
    <Table stripe max-height="620" size="small" :data="tableData.list" :columns="tableColumns" @on-selection-change="selectionChange">
    </Table>
    <Row :gutter="8">
      <i-col span="12">
        <div class="m-t-5" style="line-height:25px;">当前已选中<span style="color:#ff9900;padding:0 3px">{{
          selectedTaskItemIds.length }}</span>条</div>
      </i-col>
      <i-col span="12">
        <div class="paging_style">
          <Page size="small" :total="tableData.totalRow" :page-size="query.pageSize" show-total show-elevator show-sizer
            :page-size-opts="pagesizeSetting" @on-page-size-change='changePageSize' :current="query.pageNumber"
            @on-change="changePage"></Page>
        </div>
      </i-col>
    </Row>

    <Modal v-model="visiblePreview" width="800" :footer-hide="true">
      <p slot="header" class="text-center">{{ fileName }} {{ variableTitle }}</p>
      <Row class="m-b-5">
        <i-col class="text-right">
          <Button size="small" type="success" @click="viewBigImage">查看原图</Button>
        </i-col>
      </Row>
      <img-light-box ref="imgLightBox" :sortIndex.sync="sortIndex"></img-light-box>
    </Modal>

    <Modal v-model="modalUploadSvg" width="600" title="上传测量SVG" footer-hide>
      <Row :gutter="16">
        <i-col span="10">
          <p class="remark p-b-5">点击下方按钮或者拖拉SVG文件上传</p>
          <Upload ref="upload" :format="['svg']" accept='image/svg+xml' :before-upload="handleBeforeUpload" type="drag"
            action="" style="width:100px;">
            <div style="width: 100px;height:58px;line-height: 58px;">
              <Icon type="ios-camera" size="20"></Icon>
            </div>
          </Upload>
          <Progress v-if="percentage > 0 && percentage < 100" :percent="percentage" :stroke-width="20" status="active"
            text-inside></Progress>
          <p v-if="percentage === 100 && uploadError !== ''" class="text-red">
            上传异常： {{ uploadError }}
          </p>
        </i-col>
        <i-col span="14" v-if="svgFile">
          <p class="p-b-5 p-t-20">文件名称：{{ svgFile.name }}</p>
          <p class="p-b-5">文件大小：{{ (svgFile.size / 1024).toFixed(2) }} KB</p>
          <p class="p-b-5"><span class="m-r-5">操作：</span><a class="delete m-l-20" @click="svgFile = null">删除</a></p>
          <p><Button type="success" size="small" @click="handleUpload">开始上传</Button></p>
        </i-col>
      </Row>

    </Modal>

    <SvgMaintenanceTask ref="SvgMaintenanceTask" />
    <InstallCompletion v-if="isAuth('work_supplier_complete_manual') && [1, 7].includes(query.type)" ref="installCompletion"
      :onSuccess="refreshTableData" />
  </div>
</template>

<script>
import axios from 'axios'
import { getStorage } from '@/utils/storage'
import { GetCurrentSchedule } from '@/utils/dateFormat'

import { mapActions } from 'vuex'
import { sysMixins } from '@/assets/mixins/sys'
import { commonMixins } from '@/assets/mixins/common'
// import { companyMixin } from '@/assets/mixins/company'
import { workorderMixins } from '@/assets/mixins/workorder'

import { getTaskitemType, getPictureResource } from '@/api/msp/status'
// import { getStationByAssetIds } from '@/api/product/station'
import { getRimStationByAssetIds } from '@/api/rim/station'
import { downloadFileRequest } from '@/utils/download'
import { formartMspTaskitemStatus, formartMspTaskitemRephotoStatus } from '@/utils/tagStatus'
import { cancelWorkgroup, remakePhoto } from '@/api/msp/taskitem'
import { getPublisherAssetList } from '@/api/msp/demand'

import ImgLightBox from '@/components/common/ImgVideoLightBox'
import SvgMaintenanceTask from '@/components/svg/SvgMaintenanceTask'
import InstallCompletion from '@/components/workOrder/bottom/InstallCompletion'

export default {
  mixins: [sysMixins, commonMixins, workorderMixins],
  props: {
    serviceType: { // 服务对象类型，1：销售，2：代理商
      type: Number,
      default: 1
    }
  },
  components: {
    ImgLightBox,
    SvgMaintenanceTask,
    InstallCompletion
  },
  data () {
    return {
      companyId: this.$store.getters.token.userInfo.companyId,
      pagesizeSetting: [15, 50, 100, 200, 500],
      taskStatusArray: [
        {
          value: 1,
          name: '已派单'
        },
        {
          value: 2,
          name: '执行中'
        },
        {
          value: 3,
          name: '已完成'
        }
      ],
      actionDate: [],
      query: {
        assetId: null,
        stationId: null,
        type: null,
        startActionDate: '',
        endActionDate: '',
        status: null,
        keyword: '',
        showOrderInfo: true,
        pictureResource: null,
        adType: null,
        pageSize: 15,
        pageNumber: 1
      },
      stationArray: [],
      taskTypeArray: [],
      pictureSourceArray: [],
      adTypeArray: [], // 用途类别
      tableColumns: [],
      fileName: '',
      variableTitle: '', // 变量标题
      currentFileArray: [], // 当前预览照片反馈集合
      visiblePreview: false,
      sortIndex: 0,
      downloadActionDate: '', // 下载选择作业日期
      taskTypeShowColumns: new Map([
        [1, ['selection', 'resourceCode', 'resourceType', 'skuName', 'domainName', 'side', 'taskName', 'actionDate', 'schedule', 'actionFile', 'actionName', 'picturesource', 'supplierName', 'adTypeName', 'status', 'remark', 'operate']], // 上刊设置
        [2, ['selection', 'resourceCode', 'resourceType', 'skuName', 'domainName', 'side', 'taskName', 'actionDate', 'schedule', 'actionFile', 'actionName', 'supplierName', 'adTypeName', 'status', 'remark', 'operate']], // 监播
        // [3, ['selection', 'resourceCode', 'domainName', 'actionDate', 'actionFile', 'actionName', 'picturesource', 'supplierName', 'status', 'operate']], // 制作
        [4, ['selection', 'resourceCode', 'resourceType', 'skuName', 'domainName', 'actionDate', 'actionFile', 'supplierName', 'status', 'remark', 'operate']], // 清洁任务
        [5, ['selection', 'resourceCode', 'resourceType', 'skuName', 'domainName', 'actionDate', 'actionFile', 'supplierName', 'adTypeName', 'status', 'remark', 'operate']], // 维修
        [6, ['selection', 'resourceCode', 'resourceType', 'skuName', 'domainName', 'actionDate', 'actionFile', 'actionName', 'supplierName', 'status', 'remark', 'operate']], // 测量任务
        [7, ['selection', 'resourceCode', 'resourceType', 'skuName', 'domainName', 'side', 'taskName', 'actionDate', 'actionFile', 'actionName', 'picturesource', 'supplierName', 'adTypeName', 'status', 'remark', 'operate']], // 下刊
        [8, ['selection', 'domainName', 'actionDate', 'actionFile', 'supplierName', 'userName', 'status', 'remark', 'operate']], // 巡检
        [10, ['selection', 'resourceCode', 'assetName', 'domainName', 'actionDate', 'supplierName', 'userName', 'status', 'remark', 'operate']], // 临时任务
        [11, ['selection', 'resourceCode', 'resourceType', 'skuName', 'domainName', 'side', 'actionDate', 'actionFile', 'actionName', 'picturesource', 'supplierName', 'adTypeName', 'status', 'remark', 'operate']], // 填充任务
        [12, ['selection', 'resourceCode', 'resourceType', 'skuName', 'domainName', 'side', 'actionDate', 'actionFile', 'actionName', 'picturesource', 'supplierName', 'adTypeName', 'status', 'remark', 'operate']], // 画面维护
        [13, ['selection', 'resourceCode', 'resourceType', 'skuName', 'domainName', 'side', 'actionDate', 'actionFile', 'actionName', 'picturesource', 'supplierName', 'adTypeName', 'status', 'remark', 'operate']] // 上下刊维护
      ]),

      chooseTaskItemId: null,
      modalUploadSvg: false,
      svgFile: null,
      percentage: 0,
      uploadError: '',
      companyAssetArray: []
    }
  },
  created () {
    this.getPictureData()
    this.getPubulisherData()
    this.getTaskitemTypeData()
    // 设置头部路由标签
    this.setLinkTagArray({ key: 'supplierWorkIndex', value: '作业列表' })
    this.setLinkRouterMaps(['supplierWorkIndex', () => { this.handleIndexPage() }])
    this.setActivedTagName('supplierWorkIndex')
  },
  destroyed () {
    this.selectedTaskItemIds = []
    this.tempSelectedTaskItemIds = []
  },
  computed: {
    tableData () {
      var result = this.$store.state.supplierWorkOrder.taskData
      if (result.list) {
        result.list.forEach(item => {
          item._disabled = false
          item._checked = false
          // 禁用已完成或者挂起状态
          if (item.status === 3 || item.hangUpStatus === 2) {
            item._disabled = true
          }
          if (this.$store.state.supplierWorkOrder.selectedTaskItemIds.some(x => x === item.id)) {
            item._checked = true
          }
        })
      }
      return result
    },
    selectedTaskItemIds: {
      get () {
        return this.$store.state.supplierWorkOrder.selectedTaskItemIds
      },
      set (val) {
        this.$store.commit('set_selected_task_item_ids', val)
      }
    },
    tempSelectedTaskItemIds: { // 临时存储分页复选框选中数据
      get () {
        return this.$store.state.supplierWorkOrder.tempSelectedItem
      },
      set (val) {
        this.$store.commit('set_temp_selected_item', val)
      }
    }
  },
  methods: {
    ...mapActions({
      getTableData: 'getSupplierTaskData'
    }),
    /**
     * 动态生成Table列表
     */
    getTableColumns () {
      const tableColumnArray = {
        selection: { type: 'selection', width: 60, align: 'center', fixed: 'left' },
        resourceCode: { title: this.query.type === 10 ? '任务名称' : '资源编号', minWidth: 80, key: 'resourceCode', align: 'center' },
        resourceType: { title: '资源类型', key: 'resourcetypeName', align: 'center', minWidth: 80 },
        assetName: { title: '所属资产', key: 'assetName', align: 'center', minWidth: 80 },
        domainName: { title: '所属站点', key: 'domainName', align: 'center', minWidth: 80 },
        adTypeName: { title: '用途', key: 'adTypeName', align: 'center', minWidth: 80 },
        taskName: { title: '任务名称', key: 'taskName', align: 'center', minWidth: 80 },
        side: { title: '面数', key: 'side', minWidth: 60, align: 'center' },
        skuName: {
          title: '单品',
          key: 'skuName',
          minWidth: 100,
          align: 'center',
          render: (h, params) => {
            return h('span', params.row.skuId > -1 ? params.row.skuName : '/')
          }
        },
        customerBrand: {
          title: '客户品牌',
          align: 'center',
          minWidth: 80,
          render: (h, params) => {
            if (params.row.taskOrder && params.row.taskOrder.brandName) {
              const customerBrand = `${params.row.taskOrder.advertiserName}（${params.row.taskOrder.brandName}）`
              return h('Tooltip', {
                props: {
                  content: customerBrand,
                  transfer: true,
                  'max-width': '220px'
                }
              }, customerBrand.length > 16 ? customerBrand.substr(0, 15) + '...' : customerBrand)
            }
          }
        },
        finishDate: { title: '当前在刊结束日', key: 'finishDate', align: 'center', minWidth: 80 },
        actionDate: {
          title: '作业日期',
          key: 'actionDate',
          align: 'center',
          minWidth: 100,
          render: (h, params) => {
            return h('div', [
              h('p', params.row.actionDate),
              h('Tag', {
                props: { color: params.row.actionDateModifyStatus === 1 ? 'default' : 'primary' }
              }, params.row.actionDateModifyStatusName)
            ])
          }
        },
        actionFile: {
          title: '作业画面',
          align: 'center',
          minWidth: 80,
          render: (h, params) => {
            if (params.row.taskitemFiles && params.row.taskitemFiles.length > 0) {
              if (params.row.taskitemFiles.some(x => (x.mimeType.includes('video') || x.mimeType.includes('audio')))) {
                return h('a', {
                  on: {
                    click: () => {
                      const postData = {
                        startIndex: 0,
                        imgList: params.row.taskitemFiles
                      }
                      this.sortIndex = 0
                      this.$refs.imgLightBox.init(postData)
                      this.currentFileArray = params.row.taskitemFiles
                      this.fileName = `${params.row.domainName} ${params.row.code}`
                      this.variableTitle = ''
                      this.showCheckbox = false
                      this.visiblePreview = true
                    }
                  },
                  style: {
                    cursor: 'pointer'
                  }
                }, '查看素材')
              } else {
                return h('div', [
                  h(
                    'img',
                    {
                      domProps: {
                        src: params.row.taskitemFiles[0].fileKey + '?x-oss-process=image/resize,w_64'
                      },
                      on: {
                        click: () => {
                          const postData = {
                            startIndex: 0,
                            imgList: params.row.taskitemFiles
                          }
                          this.sortIndex = 0
                          this.fileName = `${params.row.domainName} ${params.row.code}`
                          this.variableTitle = ''
                          this.currentFileArray = params.row.taskitemFiles
                          this.$refs.imgLightBox.init(postData)
                          this.visiblePreview = true
                        }
                      },
                      style: {
                        width: '64px',
                        cursor: 'pointer'
                      }
                    }
                  )
                ])
              }
            } else {
              return h('span', '暂无画面')
            }
          }
        },
        actionName: {
          title: '作业名称',
          align: 'center',
          minWidth: 80,
          render: (h, params) => {
            return h('span', Array.from(new Set(params.row.taskitemFiles.map(x => x.fileName))))
          }
        },
        picturesource: { title: '画面来源', key: 'picturesourceName', align: 'center', minWidth: 80 },
        supplierName: {
          title: '负责小组',
          align: 'center',
          minWidth: 80,
          render: (h, params) => {
            return h('span', params.row.taskitemLabor.workgroupName)
          }
        },
        userName: {
          title: '负责人员',
          align: 'center',
          minWidth: 80,
          render: (h, params) => {
            return h('span', params.row.taskitemLabor.userName)
          }
        },
        schedule: {
          title: '订单档期',
          minWidth: 120,
          key: 'schedule',
          align: 'center',
          render: (h, params) => {
            return h('span',
              GetCurrentSchedule(params.row.startDate, params.row.endDate, false)
            )
          }
        },
        status: {
          title: '状态',
          width: 95,
          align: 'center',
          render: (h, params) => {
            const html = [
              formartMspTaskitemStatus(h, params.row.status, params.row.statusName),
              formartMspTaskitemRephotoStatus(h, params.row.rephoto)
            ]
            if (params.row.hangUpStatus === 2) { // 挂起
              html.push(h('div', [
                h('Tag', {
                  props: {
                    color: 'warning'
                  }
                }, '已挂起')
              ]))
            }
            return h('div', html)
          }
        },
        remark: {
          title: '备注',
          align: 'center',
          minWidth: 100,
          render: (h, params) => {
            const remark = `${params.row.remark} ${params.row.extra}`
            return h('Tooltip', {
              props: {
                content: remark,
                transfer: true,
                'max-width': '200px'
              }
            }, remark.length > 12 ? remark.substr(0, 11) + '...' : remark)
          }
        },
        operate: {
          title: '操作',
          align: 'center',
          minWidth: 100,
          render: (h, params) => {
            var html = []
            if (params.row.type === 6) { // 测量任务
              if (params.row.status === 2 || (params.row.status === 3 && params.row.rephoto === 1)) { // 执行中，执行上传SVG操作
                html.push(h('a', {
                  style: { marginRight: '5px' },
                  on: {
                    click: () => {
                      this.chooseTaskItemId = params.row.id
                      this.modalUploadSvg = true
                    }
                  }
                }, '上传测量SVG'))
              } else if (params.row.status === 3) { // 已完成，查看详情
                html.push(h('a', {
                  style: { marginRight: '5px' },
                  on: {
                    click: () => {
                      this.$refs.SvgMaintenanceTask.initPageData(params.row)
                    }
                  }
                }, '查看详情'))
              }
            } else {
              if (params.row.status === 3 && [-1, 2].includes(params.row.taskitemFileFillWay) && this.isAuth('work_supplier_setfiles')) { // 下刊任务和填充任务
                html.push(h('a', {
                  style: { marginRight: '5px', display: 'block' },
                  on: {
                    click: () => {
                      this.$store.commit('set_cur_task_info', params.row)
                      this.setLeftComponent('SetFiles')
                      this.setShowLeftBlock(true)
                    }
                  }
                }, '素材补充'))
              }
              if (params.row.taskitemAttachs && params.row.taskitemAttachs.length) {
                html.push(h('a', {
                  style: { marginRight: '5px', display: 'block' },
                  on: {
                    click: () => {
                      const postData = {
                        startIndex: 0,
                        imgList: params.row.taskitemAttachs
                      }
                      this.sortIndex = 0
                      this.fileName = `${params.row.domainName} ${params.row.code}`
                      this.variableTitle = params.row.finishTime ? `（完成时间：${params.row.finishTime}）` : ''
                      this.currentFileArray = params.row.taskitemAttachs
                      this.$refs.imgLightBox.init(postData)
                      this.visiblePreview = true
                    }
                  }
                }, '照片反馈'),
                h('a', {
                  style: { marginRight: '5px', display: 'block' },
                  on: {
                    click: () => {
                      this.handleRemakePhotoTask(params.row)
                    }
                  }
                }, '重拍'))
              }
              if ([1, 7].includes(this.query.type) && [1, 2].includes(params.row.status) && this.isAuth('work_supplier_complete_manual')) { // 非已完成的上刊任务
                html.push(h('a', {
                  // 非巡检任务操作
                  style: { display: 'block' },
                  on: {
                    click: () => {
                      this.handleCompletionInstall(params.row)
                    }
                  }
                }, '完成任务'))
              }
            }
            return h('div', html)
          }
        }
      }

      const data = []
      const showColumns = this.taskTypeShowColumns.get(this.query.type)
      if (showColumns && showColumns.length) {
        showColumns.forEach(col => data.push(tableColumnArray[col]))
      }

      return data
    },
    getPubulisherData () {
      getPublisherAssetList().then(res => {
        if (res && !res.errcode) {
          this.companyAssetArray = res
        } else {
          this.companyAssetArray = []
        }
      })
    },
    handleSearch () {
      this.query.pageNumber = 1
      this.query.startActionDate = ''
      this.query.endActionDate = ''
      if (this.actionDate.length && this.actionDate[0]) {
        const sDate = new Date(this.actionDate[0])
        const eDate = new Date(this.actionDate[1])
        this.query.startActionDate = `${sDate.getFullYear()}-${(sDate.getMonth() + 1).toString().padStart(2, '0')}-${sDate.getDate().toString().padStart(2, '0')}`
        this.query.endActionDate = `${eDate.getFullYear()}-${(eDate.getMonth() + 1).toString().padStart(2, '0')}-${eDate.getDate().toString().padStart(2, '0')}`
      }

      this.selectedTaskItemIds = []
      this.tempSelectedTaskItemIds = []
      this.getTableData(this.query)
    },
    refreshTableData () {
      this.getTableData(this.query)
    },
    changePage (curPage) {
      this.query.pageNumber = curPage
      this.getTableData(this.query)
    },
    changePageSize (pagesize) {
      this.query.pageNumber = 1
      this.query.pageSize = pagesize
      this.selectedTaskItemIds = []
      this.tempSelectedTaskItemIds = []
      this.getTableData(this.query)
    },
    getPictureData () {
      getPictureResource().then(res => {
        if (res && !res.errcode) {
          this.pictureSourceArray = res
        } else {
          this.pictureSourceArray = []
        }
      })
    },
    /**
     * 获取用途数据
     */
    getAdTypeData () {
      this.query.adType = null
      this.getOrderAdTypeData(this.query.type).then(res => {
        this.adTypeArray = res
      })
    },
    changeTaskType () {
      if (![7, 11].includes(this.query.type)) { // 非下刊任务和填充任务，不显示左侧素材补充操作界面
        this.$store.commit('set_cur_task_info', {})
        this.setLeftComponent('')
        this.setShowLeftBlock(false)
      }
      this.tableColumns = this.getTableColumns()
      this.getAdTypeData()
      this.handleSearch()
    },
    selectionChange (selections) {
      this.handelPageSelections(this.tempSelectedTaskItemIds, selections.map(x => x.id), this.query.pageNumber)
      // 最终选中的所有taskItemIds
      this.selectedTaskItemIds = []
      this.tempSelectedTaskItemIds.map(x => x.existSelections).forEach(item => {
        this.selectedTaskItemIds = this.selectedTaskItemIds.concat(item)
      })
    },
    handelChangeActionDate (date) {
      this.actionDate = date
    },
    /**
     * 获取任务详情类型
     */
    getTaskitemTypeData () {
      getTaskitemType().then(res => {
        if (res && !res.errcode) {
          this.taskTypeArray = res.length ? res.filter(x => x.value !== 3) : [] // 取消制作菜单
          if (this.taskTypeArray.length) {
            this.query.type = this.taskTypeArray[0].value
            this.tableColumns = this.getTableColumns()
            this.getTableData(this.query)
            this.getAdTypeData()
          }
        } else {
          this.taskTypeArray = []
        }
      })
    },
    handleChangeAsset () {
      if (this.query.assetId) {
        getRimStationByAssetIds({ assetIds: JSON.stringify([this.query.assetId]) }).then(res => {
          if (res && !res.errcode) {
            this.stationArray = res
          } else {
            this.stationArray = []
          }
        })
      } else {
        this.stationArray = []
      }
    },
    handleSetWorkgroup () {
      this.setLeftComponent('SetWorkgroup')
      this.setShowLeftBlock(true)
    },
    handleCancelWorkgroup () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要取消所选项已分配的小组？',
        onOk: () => {
          cancelWorkgroup({ taskitemIds: JSON.stringify(this.selectedTaskItemIds) }).then(res => {
            if (res && res.errcode === 0) {
              this.$Notice.success({ desc: '操作成功' })
              this.selectedTaskItemIds = []
              this.getTableData(this.query)
            }
          })
        }
      })
    },
    handelDonwload () {
      this.downloadActionDate = ''
      this.$Modal.confirm({
        width: '400px',
        render: (h) => {
          return h('DatePicker', {
            props: {
              value: this.downloadActionDate,
              type: 'daterange',
              placeholder: '选择作业日期范围'
            },
            style: { width: '100%' },
            on: {
              input: (val) => {
                this.downloadActionDate = val
              }
            }
          })
        },
        onOk: () => {
          if (this.downloadActionDate[0] === '') {
            this.$Notice.error({ desc: '请选择作业日期范围' })
            return false
          }

          const sDate = new Date(this.downloadActionDate[0])
          const eDate = new Date(this.downloadActionDate[1])

          const downloadParams = {
            startDate: `${sDate.getFullYear()}-${(sDate.getMonth() + 1).toString().padStart(2, '0')}-${sDate.getDate().toString().padStart(2, '0')}`,
            endDate: `${eDate.getFullYear()}-${(eDate.getMonth() + 1).toString().padStart(2, '0')}-${eDate.getDate().toString().padStart(2, '0')}`,
            type: this.query.type
          }
          const filename = '作业明细(' + downloadParams.startDate + '至' + downloadParams.endDate + ').xlsx'

          downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-msp/v2/msppreport/downloadsupplierplanexcel', downloadParams, filename)
        }
      })
    },
    /**
     * 查看原图
     */
    viewBigImage () {
      this.$refs.imgLightBox.viewBigImg()
    },
    handleBeforeUpload (file) {
      this.svgFile = file
      return false
    },
    handleUpload () {
      // 开始执行上传操作
      const uploadData = new FormData()
      const that = this
      uploadData.append('taskitemId', that.chooseTaskItemId)
      uploadData.append('file', that.svgFile)
      uploadData.append('webSubmit', true)

      axios.post(process.env.VUE_APP_API_URL_V2 + '/ooh-msp/v3/apptask/finishtask',
        uploadData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'ooh-auth': getStorage('ooh-token')
          },
          withCredentials: true,
          onUploadProgress (progress) { // 处理上传文件进度条数据
            that.percentage = Math.round(progress.loaded / progress.total * 100)
          }
        }
      ).then(res => {
        if (res && res.data && !res.data.errcode) {
          that.$Notice.success({ desc: '提交成功！' })
          that.svgFile = null
          that.modalUploadSvg = false
          that.getTableData(that.query)
        } else {
          that.uploadError = res.data.errmsg
        }
      })
    },
    /**
     * 重拍
     */
    handleRemakePhotoTask (obj) {
      this.$Modal.confirm({
        title: '操作提示',
        content: '您确认要对当前的作业任务发起重拍吗？',
        onOk: () => {
          remakePhoto({ taskitemIds: JSON.stringify([obj.id]) }).then(res => {
            if (res && res.errcode === 0) {
              this.$Notice.success({ desc: '已成功发起重拍任务' })
              this.getTableData(this.query)
            } else {
              this.$Notice.error({ title: '重拍任务操作失败', desc: res.errmsg })
            }
          })
        }
      })
    },
    /**
     * 手动完成上刊
     */
    handleCompletionInstall (params) {
      this.$refs.installCompletion.showModal(params.id)
    },
    handleIndexPage () {
      this.setShowLeftBlock(false)
      this.setShowRightBlock(true)
      this.setShowMap(false)
      this.setBottomHeight('90%')
      this.setBottomComponent('IndexTable')
      this.setRightComponent('TaskTypeStatistic')
    }
  }
  // watch: {
  //   sortIndex (val) {
  //     this.variableTitle = this.currentFileArray[val].uploadTime ? `（上传时间：${this.currentFileArray[val].uploadTime}）` : ''
  //   }
  // }
}
</script>
